<template>
  <footer class="text-lg-start bg-body-tertiary text-muted">
    <section class="">
      <div class="container text-md-start">

        <div style="margin-left: 10px;text-align: center;">
          <router-link to="/my-account">
            <h5 style="font-weight: 800;color: #e1f807;" class="">
              <span style="font-weight: 500;color: white;">DEPOSIT</span>
              <br />
              MPESA PAYBILL: 575758
            </h5>
          </router-link>
          <p style="font-weight: 500;color: white;" class="">
            <img style="width: 40px;" src="/img/BottomNav/18.png" />
            <br />
            Play Responsibly
            <br />
            Not for persons under the age of 18 years.
          </p>
          <p style="font-size: 14px;" class="text-light">
            <a href="tel:0716797777" class="contact-link">
              <span style="color: white;">
                <img style="width: 14px;" src="/img/home/iphone.png" alt="Call Tucheze.com"> : 0716797777
              </span>
            </a>
            <a href="mailto:help@tucheze.com" class="contact-link">
              <span style="color: white;">
                <img style="width: 14px;" src="/img/home/email.png" alt="Email Tucheze.com"> : help@tucheze.com
              </span>
            </a>
          </p>
        </div>
        <div style="margin-left: 10px;text-align: center;" class="p-1">
          <p class="text-light">
            Tucheze.com is provided by Lottotech Solutions LTD, a company registered in Kenya and licenced by the
            Betting Control of Kenya under the betting Lotteries and Gaming Act ( CAP 131, Laws of Kenya ),
            under License Numbers: BK 0000757
          </p>
        </div>
      </div>
    </section>

    <div class="liii text-center">
      <a href="https://www.facebook.com/tuchezecom?mibextid=LQQJ4d" class="footer-link title-text p-2 text-light">
        <img style="width: 25px;" src="/img/home/facebook.png" alt="Tucheze.com facebook">
      </a>
      <a href="https://x.com/tucheze_com?s=21&t=_ZmI-6RKcSSVGyAF5abuDg" class="footer-link title-text p-2 text-light">
        <img style="width: 25px;" src="/img/home/x.png" alt="Tucheze.com x">
      </a>
      <a href="https://www.instagram.com/tucheze_com?igsh=OGd6MjJqMXFqaWJ6"
        class="footer-link title-text p-2 text-light">
        <img style="width: 25px;" src="/img/home/instagram.png" alt="Tucheze.com instagram">
      </a>
      <a href="https://www.tiktok.com/@tucheze.com?_t=8pZQvDw9pDS&_r=1" class="footer-link title-text p-2 text-light">
        <img style="width: 25px;" src="/img/home/tiktok.png" alt="Tucheze.com tiktok">
      </a>
      <a href="https://wa.me/254716797777" target="_blank" class="footer-link title-text p-2 text-light">
        <img style="width: 25px;" src="/img/home/whatsapp.png" alt="Tucheze.com whatsapp">
      </a>
    </div>
    <div class="text-center p-4 text-light" style="background-color: rgba(0, 0, 0, 0.05);">
      © Copyright: Tucheze.com. All rights reserve
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<style scoped></style>